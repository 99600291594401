<template>
  <div class="menu-relative">
    <div class="Menu logo-close">
      <span class="Menu-logo mb-4">
        <router-link to="/" class="after-element">
          <img v-if="!isHeaven" src="@/assets/logo.png" class="logo" />
          <img v-else src="@/assets/img/heaven/logo.svg" style="height: 55px;"/>
        </router-link>
      </span>

      <Menu />
    </div>
  </div>
</template>
<script>
import Menu from './Menu'

export default {
  components: { Menu },
  data() {
    return {
      srcLogo: '',
    }
  },
  computed: {
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    }
  },
  methods: {
    logout() {
      this.$store.dispatch("logoutRequest").catch(function (err) {});
    },
    minimizeSidebar() {
      this.$store.commit("changeSidebarState")
    },
    logo() {
      this.srcLogo = require("@/assets/logo-collapsed.png")
      const menu = document.querySelector(".Menu")

      menu.addEventListener("mouseenter", (e) => {
        menu.classList.remove("logo-close")
      });
      menu.addEventListener("mouseleave", (e) => {
        menu.classList.add("logo-close")
      })
    }
  },
  mounted() {
    this.logo()
  }
}
</script>

<style scoped>
.menu-relative {
  position: relative;
  width: 100px;
}
.Menu {
  padding: 20px 40px;
  background: #ffffff;
  border: 1px solid #ededf0;
  box-shadow: -2px 2px 8px rgba(0, 0, 0, 0.2);
  border-radius: none;
  margin: -1px;
  max-width: 100px;
  height: 101vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: 0.3s;
  z-index: 1000 !important;
  overflow: hidden;
}
.Menu:hover {
  max-width: 300px;
}

.Menu-logo img {
  margin: 30px auto 20px;
  max-width: 100px;
  height: auto;
  transition: 0.3s;
}

.after-element {
  position: relative;
  display: flex;
  justify-content: space-between;
}
.after-element::after {
  content: "";
  width: 100px;
  height: 30px;
  background: transparent;
  display: inline-block;
  position: absolute;
  left: 27px;
  top: 30px;
}
.logo-close .after-element::after {
  background: #fff;
}
</style>