const state = {
  showMaxSidebar: true,
  statusLoading: false,
  isHeaven: false
};

const getters = {
  sidebarState: currentState => currentState.showMaxSidebar,
  getStatusLoading: currentState => currentState.statusLoading,
  getIsHeaven: state => state.isHeaven
};

const mutations = {
  changeSidebarState(currentState) {
    currentState.showMaxSidebar = !currentState.showMaxSidebar;
  },
  setStatusLoading: (state, payload) => {
    state.statusLoading = payload
  },
  setIsHeaven: (state, value) => state.isHeaven = value
};

const actions = {
  startLoading(state) {
    state.commit('setStatusLoading', true)
  },
  stopLoading(state) {
    state.commit('setStatusLoading', false)
  },
  showError(erro) {
    console.log(erro)
  },
  updateHeaven: ({commit}, data) => commit('setIsHeaven', data)
};

export {
  state,
  getters,
  mutations,
  actions,
};
