export default{
    get_answer: state => state.answer,
    get_answers: state => state.answers,
    get_claim: state => state.claim,
    get_claims: state => state.claims,
    get_claim_filters: state => state.claim_filters,
    get_locale_data: state => state.locale_data,
    get_date_range: state => state.date_range,
    get_date_ranges: state => state.date_ranges,
}
