<template>
  <header>
    <h3>{{ title }}</h3>
    <nav>
      <slot></slot>
    </nav>
  </header>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
a {
  cursor: pointer;
}
header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px 0;
  padding-bottom: 0;
  margin: 15px 0;
}
header h3 {
  font-size: 32px;
  font-weight: 600;
  color: #333;
  // font-family: "Spectral", serif;
  // font-family: 'Questrial', sans-serif;
  font-family: Montserrat;
  position: relative;
  z-index: 1;
}
header h3::after {
  content: "";
  width: 1.3rem;
  height: 1.3rem;
  background: $base-color;
  position: absolute;
  bottom: 2px;
  left: -7px;
  border-radius: 0.2rem;
  z-index: -1;
}

.btn-header {
  border: none;
  background: #00e4a0;
  color: #fff;
  outline: none;
  font-weight: 600;
  height: 50px;
  padding: 0 42px;
  border-radius: 5px;
  font-size: 14px;

  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.3s;
  box-shadow: none !important;
}
.btn-header:hover {
  transform: scale(1.07);
  box-shadow: none !important;
}
.btn-header:focus {
  box-shadow: none !important;
}
.btn-header img {
  width: 16px;
  margin-right: 12px;
}

@media screen and (max-width: 768px) {
  header {
    display: block;
  }
  header h3 {
    font-size: 24px;
  }
  header h3::after {
    width: 1rem;
    height: 1rem;
  }
  nav {
    max-width: 100%;
  }
}
</style>