import middleware from '@/router/middleware'
import authentication from '@/router/middleware/authentication'
import unauthentication from '@/router/middleware/unauthentication'

export default [{
    path: '/',
    name: 'Reclamação',
    component: () => import('../../views/Claim.vue')
  },
  {
    path: '/:id',
    name: 'Visualizar Reclamação',
    component: () => import('../../views/Claim.vue')
  },
  {
    name: 'pageNotFound',
    path: '*',
    component: () => import('../../views/PageNotFound.vue'),
  }
]