<template>
  <div class="menu-links">
    <router-link to="/reclamacoes">
      <img src="@/assets/img/icons/award.svg" />
      Reclamações
    </router-link>

    <Logout />
  </div>
</template>

<script>
import Logout from "./Logout.vue";
import Vue from "vue";

export default {
  components: { Logout },
  data() {
    return {
      sub_menu: false,
      sub_menu2: false,
      item: null,
      top: 0,
      bottom: 0,
      fimMenu: 300,
      fimSubmenu: 0,
    };
  },
  computed: {
    clubIntegration() {
      return this.$store.getters.userClub;
    },
  },
  methods: {
    eventLoginClub() {
      Vue.swal({
        title: "Acessar o GreenClub",
        text: `Para acessar, primeiro é necessario associar um produto ao Club.`,
        type: "info",
        showCancelButton: true,
        confirmButtonText: "Entrar no Club",
        cancelButtonText: "Associar produto",
        customClass: "sweet-container",
        confirmButtonClass: "button button-black mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        console.log(result);
        if (result.isConfirmed) {
          this.$store.dispatch("clubLogin").catch(function () {});
        }
        if (result.dismiss == "cancel") {
          this.$router.push("/produtos");
        }
      });
    },
    subMenu01(e) {
      this.item = e.currentTarget;
      this.top = this.item.offsetTop;
      const submenu = document.querySelector("#sub_01");
      submenu.style = `top: ${this.top}px`;
      this.bottom = this.top + submenu.offsetHeight;
      this.fimSubmenu = this.fimMenu + submenu.offsetLeft - 71;

      this.sub_menu = !this.sub_menu;
      this.sub_menu2 = false;
    },
    subMenu02(e) {
      this.item = e.currentTarget;
      this.top = this.item.offsetTop - 300;
      const submenu = document.querySelector("#sub_02");
      submenu.style = `top: ${this.top}px`;
      this.bottom = this.top + submenu.offsetHeight;
      this.fimSubmenu = this.fimMenu + submenu.offsetLeft - 20;

      this.sub_menu2 = !this.sub_menu2;
      this.sub_menu = false;
    },

    eventMouse() {
      window.addEventListener("mousemove", (e) => {
        if (
          e.clientX > this.fimSubmenu ||
          (e.clientX > this.fimMenu && e.clientY < this.top) ||
          (e.clientX > this.fimMenu && e.clientY > this.bottom)
        ) {
          this.sub_menu = false;
          this.sub_menu2 = false;
        }
      });
    },
  },
  mounted() {
    this.eventMouse();
  },
};
</script>

<style scoped>
.menu-links {
  display: flex;
  flex-direction: column;
  height: 60vh;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  padding: 0 !important;
}
.menu-links a {
  position: relative;
  display: flex;
  align-items: center;
  min-width: 200px;
  padding: 10px;
  padding-left: 0;
  cursor: pointer;
  /* padding-left: 40px; */
  color: #333;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.3s ease;
  font-size: 15px;
  color: transparent;
  margin: 0 auto;
}
.menu-links a:hover {
  text-decoration: none;
  color: #00e4a0;
}
.menu-links a img {
  filter: invert(50%);
  /* deixar o icone preto */
  /* filter: brightness(0) invert(0);; */
  transition: all 0.3s ease;
  margin: 0;
  margin-right: 40px;
  max-width: 22px;
}
.menu-links a.router-link-exact-active img,
.menu-links a:hover img {
  filter: invert(0);
}
.menu-links a.router-link-exact-active,
.menu-links a:hover {
  color: #00e4a0 !important;
}
/* .menu-links a.router-link-exact-active::before {
  content: "";
  width: 5px;
  height: 60px;
  background: #00e4a0;
  border-radius: 0px 5px 5px 0px;
  display: block;
  position: absolute;
  left: -34px;
  transition: all 0.3s ease;
} */
.menu-links a.router-link-exact-active::before {
  content: "";
  width: 7px;
  height: 7px;
  background: #00e4a0;
  border-radius: 50%;
  display: block;
  position: absolute;
  left: -25px;
  transition: all 0.3s ease;
}
img.arrow {
  /* margin: 0 !important; */
  margin-left: 10px !important;
}
/* Sub menu */
.sub-menu {
  width: 230px;
  box-shadow: 2px 6px 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  background: #fff;
  padding: 25px;
  position: fixed;
  left: 200px;
  /* top: 200px; */
  z-index: 55555 !important;
  animation: showLeft 0.3s forwards;
}
#sub_02 {
  width: 280px;
}
.sub-menu li a {
  font-weight: 600;
  color: #333;
  font-size: 14px;
  padding: 10px;

  /* background: silver; */
}
.sub-menu li a.router-link-exact-active::before {
  /* display: block;
  position: absolute; */
  left: -15px;
}
.sub-menu li a:hover {
  text-decoration: none;
  color: #000;
}
.sub-menu li a + a {
  margin-top: 20px;
}
.sub-menu li a img {
  margin-right: 25px;
}
.Menu:hover .menu-links a {
  color: #333;
}

/* --- */
.sub-menu2 {
  top: 300px;
}
.rotateArrow {
  transform: rotate(-90deg);
}

.logo-club {
  max-width: 115px !important;
  width: 115px;
  margin-left: -3px !important;
}
</style>