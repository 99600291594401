<template>
  <div class="container-menu">
    <item route="Reclamações" name="Reclamações" icon="award" />
    <!-- <item route="MyAccount" name="Conta" icon="user-config" /> -->
    <div class="Menu-item-logout" @click.prevent="logout">
      <a href="#">
        <img src="@/assets/img/icons/sign-out.svg" />
        Deslogar
      </a>
    </div>
  </div>
</template>
<script>
import Item from "./Item";

export default {
  components: {
    Item,
  },
  computed: {
    isSeller() {
      return this.$store.getters.isSeller;
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("logoutRequest").catch(function () {});
    },
  },
  data() {
    return {
      itemsSeller: [
        { route: "Sales", name: "Minhas vendas" },
        { route: "Contracts", name: "Meus contratos" },
      ],
      itemsClient: [
        { route: "Client-Purchases", name: "Minhas compras" },
        { route: "Client-Subscriptions", name: "Minhas assinaturas" },
      ],
    };
  },
};
</script>

<style scoped>
.container-menu {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px 10px;
  width: 100%;
  height: 80vh;
  overflow: auto;
}
.Menu-item,
.Menu-item-logout {
  height: 70px;
}

.Menu-item a {
  color: #333 !important;
}
.Menu-item-logout {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 5px;
  margin-bottom: 2px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}
.Menu-item-logout a {
  width: 100%;
  padding: 20px;
  display: flex;
  text-decoration: none;
  color: #333;
  font-size: 13px;
  font-weight: 600;
}
.Menu-item-logout img {
  margin-right: 20px;
  width: 17px;
}
.Menu-item-logout:hover {
  background-color: #ff0c37;
  cursor: pointer;
}
.Menu-item-logout:hover a {
  color: #fff;
}
.Menu-item-logout:hover img {
  filter: brightness(50) invert(0);
}
</style>