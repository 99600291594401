import UserLoggedService from '@/services/resources/UserLoggedService';
import router from '@/router/index'
import EmailVerificationService from '@/services/resources/EmailVerificationService';

const service = UserLoggedService.build();

export default {
    userRequest: ({
        commit,
        dispatch
    }) => {
        return new Promise((resolve, reject) => {
            service.get('')
                .then((resp) => {
                    commit('userSuccess', resp);
                    //dispatch('redirectUserToHome')
                    window.localStorage.id_user_greenn = resp.id
                    resolve(resp)
                })
                .catch((err) => {
                    commit('userError');
                    reject(err)
                    // if resp is unauthorized, logout, to
                    // dispatch('authLogout')
                });
        })

    },
    emailVerification: ({
        commit,
        dispatch
    }) => {
        const serviceEmail = EmailVerificationService.build();
        return new Promise((resolve, reject) => {
            serviceEmail.get('/check-user')
                .then((resp) => {
                    if (resp.verified === false) {
                        dispatch('redirectUserVerification')
                    }
                    commit('verifyEmail', resp.verified);
                    resolve(resp.verified);
                })
                .catch((err) => {
                    commit('userError');
                    reject(err)
                })
        })

    },
    adminUserRequest: ({
        commit,
        dispatch
    }) => {
        service.get('')
            .then((resp) => {
                // commit('adminUserSuccess', resp);
                commit('userSuccess', resp);
                dispatch('redirectUserToHome')
            })
            .catch((err) => {
                commit('userError');
                // if resp is unauthorized, logout, to
                // dispatch('authLogout')
            });
    },
    redirectUserToHome: (context) => {
        router.push('/dashboard');
    },
    redirectUserVerification: (context) => {
        if (router.history.current.path != '/verificar-email') {
            router.push(`/verificar-email`).catch(() => {});
        }
    }
}