import ClaimPublic from "@/services/resources/ClaimPublic";
const service = ClaimPublic.build();
import router from '@/router/index'
import { EventBus } from '@/main.js'


export default {
    create_claim_public: ({ commit, dispatch, getters }) => {
        dispatch('startLoading')
        service.create({ ...getters.get_claim_public, client_id: getters.get_claim_public.cliend_id })
        .then(res => {
            commit('set_claim_public', res)
            dispatch('stopLoading')
        })
        .catch(e => {
            dispatch('stopLoading')
            console.log('error list_claims', e)
        })
    },
    fetch_claim_public: async ({ commit, dispatch, getters }) => {
        dispatch('startLoading')
        service.search({ ...getters.get_claim, url: `/verify/${getters.get_claim.id}` })
        .then(res => {
            commit('set_claim_public', res.claim[0])
            commit('set_answers', res.answers)
            dispatch('stopLoading')
            return true
        })
        .catch(e => {
            dispatch('stopLoading')
            console.log('error', e)
            return false
        })
    },
    answer_claim_public: ({ commit, dispatch, getters }) => {
        dispatch('startLoading')
        service.create({ ...getters.get_claim, claim_id: getters.get_claim.id, type: 'CLIENT' }, null, '/answer')
        .then(res => {
            commit('set_claim', res)
            dispatch('stopLoading')
        })
        .catch(e => {
            dispatch('stopLoading')
            console.log('error', e)
        })
    },
    close_claim_public: ({ commit, dispatch, getters }) => {
        dispatch('startLoading')
        service.update({ ...getters.get_claim, claim_id: getters.get_claim.id }, null, `close/${getters.get_claim.id}`)
        .then(res => {
            commit('set_claim', res)
            dispatch('stopLoading')
        })
        .catch(e => {
            dispatch('stopLoading')
            console.log('error', e)
        })
    },
    //checa se a venda está na garantia
    check_claim_warranty: async ({ commit, dispatch, getters }) => {
        dispatch('startLoading')
        try {
            const res = await service.create(getters.get_claim_public, null, '/verify')
            commit('set_claim_public', { ...getters.get_claim_public, ...res })
            dispatch('stopLoading')
            return true;
        } catch (error) {
            dispatch('stopLoading')
            console.log('error', error)
            return false
        }
    },
}